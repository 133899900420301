import React from "react"
import FoldContainer from "../components/FoldContainer"
import MarginContainer from "../components/MarginContainer"
import styled from "styled-components"

const CustomFoldContainer = styled(FoldContainer)`
  background-attachment: fixed;
  background-image: url("/white-clouds.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const LiveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex: 1;
  margin-top: 2rem;
`

const VideoFrame = styled.iframe`
  width: 100%;
  height: 80%;
`

const LivePage = () => (
  <CustomFoldContainer>
    <MarginContainer>
      <LiveContainer>
        <VideoFrame
          src="https://www.youtube.com/embed/A5JkeWZQSyI"
          scrolling="no"
          frameBorder="0"
          allowFullScreen
        />
      </LiveContainer>
    </MarginContainer>
  </CustomFoldContainer>
)

export default LivePage
